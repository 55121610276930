import { SignIn } from '@humancollective/human-dashboard';
import React from 'react';
import styled from 'styled-components';

const StyledLoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
`;

const LoginPage: React.FunctionComponent = () => (
  <StyledLoginPage>
    <SignIn />
  </StyledLoginPage>
);

export default LoginPage;
